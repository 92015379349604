import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { LanguageTermModel } from 'src/app/core/models/languageterm.model';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { Translation } from 'primeng/api';
import * as _ from 'underscore';
import { CultureCode } from '@mentor-one-ui/core/state/translations/translation.state';

@Injectable({
  providedIn: 'root',
})
export class TranslationDataService {
  public languageChanged = new Subject();
  private translations: LanguageTermModel[];
  private filePath: string = '../../../assets/translations/';

  constructor(public http: HttpClient) {}

  getTranslations(cultureCode: CultureCode = 'nb-NO'): Observable<LanguageTermModel[]> {
    var lang = cultureCode == 'nb-NO' ? 'no' : 'en';
    return this.http.get<LanguageTermModel[]>(this.filePath + `${lang}.json`).pipe(tap((t) => {
        this.translations = t;
        this.languageChanged.next(1);
      }));
  }

  getPrimeTranslations(cultureCode: CultureCode = 'nb-NO'): Observable<Translation> {
    var lang = cultureCode == 'nb-NO' ? 'no' : 'en';

    return this.http.get<Translation>(this.filePath + `primeng-${lang}.json`);
  }

  public translate(term: string): string {
    let translation = this.translations?.find((f) => f.term === term);
    if (translation) {
      return translation.definition;
    } else {
      return '{' + term + '}';
    }
  }

  public createMissingTerms() {
    let getFiles: Observable<any>[] = [
      this.http.get<LanguageTermModel[]>('../../../assets/translations/no.json'),
      this.http.get<LanguageTermModel[]>('../../../assets/translations/en.json'),
    ];

    forkJoin(getFiles).subscribe((compare) => {
      let noTranlation = compare[0];
      let enTranlation = compare[1];
      let diff = _.difference(_.pluck(noTranlation, 'term'), _.pluck(enTranlation, 'term'));

      let missing: LanguageTermModel[] = [];

      _.each(diff, (d) => {
        let find: LanguageTermModel;
        find = _.findWhere(noTranlation, { term: d });

        if (find != null) {
          find.definition = find.definition;
          missing.push(find);
        }
      });

      console.log(JSON.stringify(missing));
    });
  }
}
