import { createAction, props } from "@ngrx/store";
import { OvertimeModel } from "../models/overtime.model";
import { OvertimeType } from "../models/overtime-type.model";

export const OvertimeApiActions = {
  loadOvertimeTypesSuccess: createAction(
    '[Overtime API] Load Overtime Types Success',
    props<{ overtimeTypes: OvertimeType[] }>()
  ),
  loadOvertimeTypesError: createAction(
    '[Overtime API] Load Overtime Types Error',
    props<{ message: string }>()
  ),
  loadOvertimesSuccess: createAction(
    '[Overtime API] Load Overtimes Success',
    props<{ overtimes: OvertimeModel[], totalItems: number  }>()
  ),
  loadOvertimesError: createAction(
    '[Overtime API] Load Overtimes Error',
    props<{ message: string }>()
  ),

  registerOvertimeSuccess: createAction(
    '[Overtime API] Register Overtime Success',
    props<{ overtime: OvertimeModel }>()
  ),

  registerOvertimeCollectionSuccess: createAction(
    '[Overtime API] Register Overtime Collection Success'),

  registerOvertimeError: createAction(
    '[Overtime API] Register Overtime Error',
    props<{ message: string }>()
  ),
  registerOvertimeCollectionError: createAction(
    '[Overtime API] Register Overtime Collection Error',
    props<{ message: string }>()
  ),

  UpdateOvertimeSuccess: createAction(
    '[Overtime API] Update Overtime Success',
    props<{ overtime: OvertimeModel }>()
  ),

  UpdateOvertimeError: createAction(
    '[Overtime API] Update Overtime Error',
    props<{ message: string }>()
  ),

  DeleteOvertimeSuccess: createAction(
    '[Overtime API] Delete Overtime Success',
    props<{ overtimeId: number }>()
  ),

  DeleteOvertimeError: createAction(
    '[Overtime API] Delete Overtime Error',
    props<{ error: string }>()
  ),



};
